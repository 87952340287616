import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  contentWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '100px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '24px',
    wordSpacing: 4,
  },
  stepWrapper: {
    width: '60%',
    marginTop: '20px',
  },
  stepper: {
    margin: '0 50px 50px 50px',
  },
  stepIcon: {
    color: `${theme.appPalette.orange} !important`,
  },
  bookTourWrapper: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
  },
  tourContainer: {
    cursor: 'pointer',
    border: '1px solid #e5e7eb',
    borderRadius: '10px',
    overflow: 'hidden',
    '&:hover': {
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
    },
  },
  tourContainerSelected: {
    border: '2px solid #f97316',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  tourBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
  },
  tourHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',
  },
  tourRoundedIconBox: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    border: '2px solid #e5e7eb',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tourRoundedIconSelectedBox: {
    border: '2px solid #f97316',
  },
  tourRoundedIcon: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    background: '#DB5C0E',
  },
  tourIcon: {
    color: '#DB5C0E',
    fontSize: '40px',
  },
  tourIconKey: {
    transform: 'rotate(-50deg)',
  },
  tourTitleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',
    gap: '10px',
  },
  tourTitle: {
    fontWeight: 'bold',
  },
  tourTitleRecommended: {
    background: '#5dc55f',
    color: 'white',
    fontWeight: 'bold',
    padding: '4px 8px',
    borderRadius: '20px',
  },
  tourSubTitle: {
    color: '#57616d',
    marginBottom: '4px',
  },
  tourDescription: {
    color: '#818793',
    fontSize: '13px',
  },
  detailsWrapper: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
  },
  sendButton: {
    color: 'black',
    marginTop: '100px',
    fontSize: '24px',
    fontWeight: 'bold',
    borderRadius: '15px',
  },
  description: {
    marginTop: '50px',
    fontSize: '22px',
  },
  nextButton: {
    alignSelf: 'end',
    marginTop: '50px',
  },
  navButtons: {
    marginTop: '50px',
    display: 'flex',
    gap: '10px',
    alignSelf: 'flex-end',
  },
});
