import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  MenuItem,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import { RouteComponentProps } from 'react-router-dom';
import InputMask, { InputState } from 'react-input-mask';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import styles from './styles';
import { shareCustomerValidationSchema } from './ShareInputValidationSchema';
import { beautifyErrors, ValidationErrors } from '../../utils/helpers';
import { ShareCustomer } from '../../redux/types/customers';
import { catalogVisitorTypes } from '../../constants/catalog';
import { Property } from '../../redux/types/properties';
import { BookTour } from './types';
import CustomButton from '../../components/CustomButton';

type Props = {
  shareCustomer: (payload: ShareCustomer) => void,
  loadBuilderProperties: () => void,
  properties: Property[],
} & WithStyles<typeof styles> & RouteComponentProps;

const initialState: ShareCustomer = {
  firstname: '',
  lastname: '',
  email_1: '',
  cell_phone_1: '',
  property_id: 0,
  visitor_type_id: 0,
  book_tour: undefined,
  visit_date: new Date(),
  visit_time: '',
};

const Connector = withStyles({
  alternativeLabel: { top: 9 },
  active: { '& $line': { borderColor: '#DB5C0E' } },
  completed: { '& $line': { borderColor: '#DB5C0E' } },
  line: {
    borderTopWidth: 2,
  },
  vertical: {
    marginLeft: 9,
  },
  lineVertical: {
    borderLeftWidth: 2,
  },
})(StepConnector);

const defaultSteps = [
  'Book a tour',
  'Customer Details',
];

const Share: React.FC<Props> = (props) => {
  const {
    classes,
    shareCustomer,
    loadBuilderProperties,
    properties,
  } = props;
  const [customer, setCustomer] = useState<ShareCustomer>(initialState);
  const [validationErrors, setValidationErrors] = useState<
  ValidationErrors<ShareCustomer>
  >({});
  const [pageIndex, setPageIndex] = useState<number>(0);

  useEffect(() => {
    loadBuilderProperties();
    setValidationErrors({});

    return () => setCustomer(initialState);
  }, [loadBuilderProperties]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      [name]: value,
    }));
  };

  const handleSendInvitation = () => {
    try {
      const validateCustomer = shareCustomerValidationSchema.validateSync(customer, {
        abortEarly: false,
        context: {
          isScheduledTour: customer.book_tour === BookTour.SCHEDULEDTOUR,
        },
      }) as ShareCustomer;
      shareCustomer(validateCustomer);
      setValidationErrors({});
      setPageIndex(0);
      setCustomer(initialState);
    } catch (errors) {
      setValidationErrors(beautifyErrors(errors));
    }
  };

  const createMenuItemArray = (
    items: {catalog_id: number; value: string}[],
  ) => items.map((item) => (
    <MenuItem key={item.value} value={item.catalog_id}>
      {item.value}
    </MenuItem>
  ));

  const createPropertyItemArray = (items: Property[]) => items.map((item) => (
    <MenuItem key={item.property_id} value={item.property_id}>
      {item.property_id}
      ,
      {' '}
      {item.house_num}
      {' '}
      {item.address_1}
    </MenuItem>
  ));

  const handleDateChange = (date: Date | null) => {
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      visit_date: date ?? new Date(),
    }));
  };

  const generateTimes = (): JSX.Element[] => {
    const result: JSX.Element[] = [];
    const startTime = new Date();
    startTime.setHours(7, 0, 0, 0); // 7:00 AM

    const endTime = new Date();
    endTime.setHours(20, 30, 0, 0); // 8:30 PM

    const formatTime = (date: Date): string => {
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const period = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12;
      return `${hours}:${minutes < 10 ? '00' : minutes} ${period}`;
    };

    while (startTime <= endTime) {
      result.push(
        <MenuItem key={startTime.toString()} value={formatTime(startTime)}>
          {formatTime(startTime)}
        </MenuItem>,
      );
      startTime.setMinutes(startTime.getMinutes() + 30);
    }

    return result;
  };

  const handlePreviousPage = () => {
    setValidationErrors({});
    setPageIndex(pageIndex - 1);
  };

  const handleNextPage = () => {
    if (!customer.book_tour) {
      setValidationErrors((prevState) => ({
        ...prevState,
        book_tour: 'Book Tour is required',
      }));
      return;
    }
    setPageIndex(pageIndex + 1);
  };

  const tourOptions = [
    {
      title: 'Create Verified NterNow Account',
      subtitle: 'Sends a invite for a home-buyer to tour your homes. This will bypass our identity verification.',
      description: 'Email a home-buyer a username/password to tour any of your self-guided tour homes on NterNow. We will send along the webflow links that will show all of your available self-tour homes that they can tour.',
      type: BookTour.VERIFIEDCUSTOMER,
      icon: <VpnKeyOutlinedIcon className={`${classes.tourIcon} ${classes.tourIconKey}`} />,
    },
    {
      title: 'Send Scheduled Tour Invite',
      subtitle: 'Invite a potential home buyer or agent to your homes and use NterNow',
      description: 'This option will send an email to a homebuyer or agent to use NterNow to your homes. They will need to go through the verification process to prove their identity. They can schedule or tour on-demand!',
      type: BookTour.SCHEDULEDTOUR,
      icon: <CalendarTodayOutlinedIcon className={classes.tourIcon} />,

    },
  ];

  const handleCardSelect = (type: BookTour) => {
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      book_tour: type,
    }));
  };

  return (
    <>
      <Box className={classes.contentWrapper}>
        <Typography variant="h4" className={classes.title}>Share Self Tour Link</Typography>
        <Box className={classes.stepWrapper}>
          <Stepper
            alternativeLabel
            className={classes.stepper}
            activeStep={pageIndex}
            connector={<Connector />}
          >
            {defaultSteps.map((label) => (
              <Step key={label}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      active: classes.stepIcon,
                      completed: classes.stepIcon,
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {pageIndex === 0 && (
        <Box className={classes.bookTourWrapper}>
          <Typography variant="h4" className={classes.title}>Book a tour</Typography>
          {tourOptions.map((option) => (
            <Box
              key={option.type}
              className={`${classes.tourContainer} ${customer.book_tour === option.type ? classes.tourContainerSelected : ''}`}
              onClick={() => handleCardSelect(option.type)}
            >
              <Box className={classes.tourBox}>
                <Box className={classes.tourHeader}>
                  {option.icon}
                  <Box className={`${classes.tourRoundedIconBox} ${customer.book_tour === option.type ? classes.tourRoundedIconSelectedBox : ''}`}>
                    {customer.book_tour === option.type && (
                      <Box className={classes.tourRoundedIcon} />
                    )}
                  </Box>
                </Box>

                <Box className={classes.tourTitleBox}>
                  <Typography variant="h6" className={classes.tourTitle}>
                    {option.title}
                  </Typography>
                  {option.type === BookTour.SCHEDULEDTOUR && (
                    <span className={classes.tourTitleRecommended}>Recommended</span>
                  )}
                </Box>

                <Typography variant="subtitle1" className={classes.tourSubTitle}>
                  {option.type === BookTour.VERIFIEDCUSTOMER ? (
                    <span>
                      Sends a invite for a home-buyer to tour your homes. This will <b>bypass</b> our identity verification.
                    </span>
                  ) : (
                    option.subtitle
                  )}
                </Typography>

                <Typography variant="body2" className={classes.tourDescription}>
                  {option.description}
                </Typography>
              </Box>
            </Box>
          ))}
          <CustomButton
            variant="orange"
            onClick={handleNextPage}
            className={classes.nextButton}
            disabled={!customer.book_tour}
            style={{
              background: customer.book_tour ? '#DB5C0E' : '#e8eaed',
            }}
          >
            Next
          </CustomButton>
        </Box>
        )}
        {pageIndex === 1 && (
        <Box className={classes.detailsWrapper}>
          <Typography variant="h4" className={classes.title}>Customer Details</Typography>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <TextField
                margin="none"
                fullWidth
                label="First Name"
                name="firstname"
                required
                value={customer.firstname}
                onChange={handleChange}
                error={Boolean(validationErrors.firstname)}
                helperText={validationErrors.firstname}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="none"
                fullWidth
                label="Last Name"
                name="lastname"
                required
                value={customer.lastname}
                onChange={handleChange}
                error={Boolean(validationErrors.lastname)}
                helperText={validationErrors.lastname}
              />
            </Grid>
            <Grid item xs={6}>
              <InputMask
                mask="999-999-9999"
                onChange={handleChange}
                value={customer.cell_phone_1}
              >
                {(inputProps: InputState) => (
                  <TextField
                    {...inputProps}
                    type="tel"
                    margin="none"
                    required
                    fullWidth
                    label="Phone"
                    name="cell_phone_1"
                    error={Boolean(validationErrors.cell_phone_1)}
                    helperText={validationErrors.cell_phone_1}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="none"
                fullWidth
                label="Email"
                name="email_1"
                required
                value={customer.email_1}
                onChange={handleChange}
                error={Boolean(validationErrors.email_1)}
                helperText={validationErrors.email_1}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="none"
                fullWidth
                label="Property"
                name="property_id"
                required
                value={customer.property_id}
                select
                onChange={handleChange}
                error={Boolean(validationErrors.property_id)}
                helperText={validationErrors.property_id}
              >
                {createPropertyItemArray(properties)}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="none"
                fullWidth
                label="Visitor Type"
                name="visitor_type_id"
                required
                value={customer.visitor_type_id}
                select
                onChange={handleChange}
                error={Boolean(validationErrors.visitor_type_id)}
                helperText={validationErrors.visitor_type_id}
              >
                {createMenuItemArray(catalogVisitorTypes)}
              </TextField>
            </Grid>
            {customer.book_tour === BookTour.SCHEDULEDTOUR && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  variant="inline"
                  fullWidth
                  label="Visit Date"
                  name="visit_date"
                  value={customer.visit_date}
                  placeholder="10/10/2012"
                  onChange={handleDateChange}
                  disableToolbar
                  format="MM/dd/yyyy"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  autoOk
                />
              </Grid>
            </MuiPickersUtilsProvider>
            )}
            {customer.book_tour === BookTour.SCHEDULEDTOUR && (
            <Grid item xs={6}>
              <TextField
                margin="none"
                fullWidth
                label="Visit Time"
                name="visit_time"
                value={customer.visit_time}
                select
                onChange={handleChange}
                error={Boolean(validationErrors.visit_time)}
                helperText={validationErrors.visit_time}
              >
                {generateTimes()}
              </TextField>
            </Grid>
            )}
          </Grid>
          <Box className={classes.navButtons}>
            <CustomButton variant="white" onClick={handlePreviousPage}>
              Previous
            </CustomButton>
            <CustomButton variant="orange" onClick={handleSendInvitation}>
              Save
            </CustomButton>
          </Box>
        </Box>
        )}
      </Box>
    </>
  );
};
export default React.memo(Share);
